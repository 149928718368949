import React from "react";
import axios from "axios";
import { API_URL } from "./constants/Statics";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: API_URL,
});

// Set Access-Control-Allow-Origin header
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// Set the JWT token from localStorage or sessionStorage if available
const JWT_token = localStorage.token || sessionStorage.token;
if (JWT_token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

// Add request interceptor
instance.interceptors.request.use(
  async (config) => {
    // Use token from localStorage or sessionStorage
    const JWT_token = localStorage.token || sessionStorage.token;
    if (JWT_token) {
      config.headers.common["Authorization"] = `Bearer ${JWT_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Show toast notification for errors
    toast.error(`${error.response?.data?.message || "An error occurred"}`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (error.response?.status === 401 || error.response?.status === 500) {
      const itemsToRemove = ["token", "userData", "email", "deviceId"];

      itemsToRemove.forEach((item) => {
        localStorage.removeItem(item);
        sessionStorage.removeItem(item);
      });
      // localStorage.removeItem("token");
      // localStorage.removeItem("userData");
      // localStorage.removeItem("email");
      // localStorage.removeItem("deviceId");
      // sessionStorage.removeItem("userData");
      // sessionStorage.removeItem("token");
      // sessionStorage.removeItem("email");
      // sessionStorage.removeItem("deviceId");
      window.location.pathname = "/adminPanel";
    }

    return Promise.reject(error);
  }
);

export default instance;

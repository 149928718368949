import React, { useEffect, useState } from "react";
import "./style.scss";
import axios from "../../axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { IoArchive, IoChevronBack } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

function ViewProfile() {
  const history = useHistory();
  const { state } = useLocation();
  console.log(state?.state?.page);
  const param = useParams();
  const [profile, setProfile] = useState();
  console.log(param);
  const data = async () => {
    try {
      const resp = await axios.get(`/getProfileById/${param.id}`);
      setProfile(resp?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    data();
  }, []);

  const block = async (id) => {
    console.log("ID:", id);
    try {
      const response = await axios.put(`/private/changeStatus/${id}`);
      toast.success(response.data.message);
      data();
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const archive = async (id) => {
    console.log("ID:", id);
    try {
      let formData = {
        status: "3",
      };
      const response = await axios.put(`/changeAccountStatus/${id}`, formData);
      toast.success(response.data.message);
      data();
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  console.log(profile);
  return (
    <section className="hewe-cont">
      <div className="viewProfile">
        <div className="flex-align-center">
          <IoChevronBack
            size={40}
            onClick={() =>
              history.push("/accountManagement", {
                state: { page: state?.state?.page },
              })
            }
          />
          <h3 className="font-g1 mb-0 ms-3">User Profile</h3>
        </div>
        <div className="cont">
          <div className="headcont">
            <div className="flex-align-center">
              <FaUser className="font-g2" />
              <h5 className="font-dark-green text-capitalize ps-3 mb-0">
                {profile?.profileName}
              </h5>
            </div>
            <div className="flex-align-center">
              <div
                className="viewbtn font-yellow"
                onClick={() => block(profile?._id)}
              >
                <MdBlock />{" "}
                <span className="ps-2 tu">
                  {profile?.status == 1 ? "UnBlock" : "Block"}
                </span>
              </div>
              {profile?.accountStatus != "3" ? (
                <div
                  className="font-r1 arcbtn"
                  onClick={() => archive(profile?._id)}
                >
                  <IoArchive /> <span className="ps-2 tu">Archive</span>
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-4 px-5 py-3">
                <div className="tbdata">
                  <p className="key">Email Address</p>
                  <p className="value">{profile?.email}</p>
                </div>
                <div className="tbdata">
                  <p className="key">CurrentRank</p>
                  <p className="value">{profile?.CurrentRank}</p>
                </div>
                <div className="tbdata">
                  <p className="key">Phone number</p>
                  <p className="value">
                    {profile?.countryCode}
                    {profile?.phoneNumber}
                  </p>
                </div>
                <div className="tbdata">
                  <p className="key">Gender</p>
                  <p className="value">
                    {profile?.gender === "1" ? "Male" : profile?.gender === "2"  ? "Female" : "Other"}
                  </p>
                </div>
                <div className="tbdata">
                  <p className="key">Date of birth</p>
                  <p className="value">{profile?.dob}</p>
                </div>
                <div className="tbdata">
                  <p className="key">Level</p>
                  <p className="value">{profile?.CurrentLevel}</p>
                </div>
              </div>
              <div className="col-md-4 px-5 py-3">
                <div className="tbdata">
                  <p className="key">Country</p>
                  <p className="value">{profile?.country}</p>
                </div>
                <div className="tbdata">
                  <p className="key">State</p>
                  <p className="value">{profile?.state}</p>
                </div>
                <div className="tbdata">
                  <p className="key">City</p>
                  <p className="value">{profile?.city}</p>
                </div>
                <div className="tbdata">
                  <p className="key">HEWE Coin</p>
                  <p className="value">{profile?.HeweCoins}</p>
                </div>
                <div className="tbdata">
                  <p className="key">AMC Coin</p>
                  <p className="value">{profile?.amcCoins}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewProfile;

import React, { useState, useEffect } from "react";
import { IoIosLogOut } from "react-icons/io";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
} from "./ManageDeviceElement";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../components/Input";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from "moment";
import { Field, Form } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaGlobe } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { TiInfoOutline } from "react-icons/ti";
import { GrApple, GrAndroid } from "react-icons/gr";
import { SlClose } from "react-icons/sl";
import { Modal } from "../../components/Modal";
import { TbCircleDotFilled } from "react-icons/tb";

const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    // height: "508px",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  tableMainContainer: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 0px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
  },
  textMiddle: {
    padding: "0.6rem 0px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#001050",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
}));

const ManageDevice = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const userDeviceId = localStorage.getItem("deviceId") || sessionStorage.getItem("deviceId");
  const [tableData, SetTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getDeviceData();
  }, []);

  const reversedData = [...tableData].reverse();

  const getDeviceData = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get(`/private/accountsLogin`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      SetTableData(data?.data);
    } catch (error) {
        if (error?.response.status === 401) {
          toast.success(`${error.response.data?.errors[0].msg}`, {
            position: toast.POSITION.TOP_RIGHT,
          });

          history.push("/");
        } else {
          toast.error(`Something went wrong`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignout = async () => {
    setIsLoading(true);
    setOpenModal(false);

    try {
      const { data } = await axios.delete(`/private/signout/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setIsLoading(false);
      getDeviceData();
      toast.success(data.message);

      if (userId == userDeviceId) {
        const itemsToRemove = ["token", "userData", "email", "deviceId"];

        itemsToRemove.forEach((item) => {
          localStorage.removeItem(item);
          sessionStorage.removeItem(item);
        });
        Cookies.remove("showProfileSidebar");
        history.push("/");
      }
    } catch (error) {
      console.error("Error signing out:", error.response);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading>
              {/* <div
                className="left-arrow"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Cookies.set("showProfileSidebar", false, { expires: 365 });
                  history.push({
                    pathname: "/dashboard",
                  });
                }}
              >
                <FaArrowLeft style={{ fontSize: "1.2rem", color: "#264503" }} />
              </div> */}
              <DashHeading>Manage Device</DashHeading>
            </DashboardHeading>

            <Paper className={classes.paperTableHeight}>
              <>
                <TableContainer className={classes.tableMainContainer} style={{ minHeight: tableData.length > 0 ? "50vh" : "" }}>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell className={classes.tablseHeadingCell}>
                          S.No
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          First Sign In
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Device Id
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Device Name
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Last Active
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reversedData.map((category, index) => (
                        <TableRow key={category._id}>
                          <TableCell className={classes.textMiddle}>
                            {index + 1 + page * rowsPerPage}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>
                              {" "}
                              {new Date(
                                get(category, "createdAt", "")
                              ).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {userDeviceId === category?.deviceId ? (
                              <div style={{ display: "flex", alignItems: "center", justifyContent:"center", gap: "4px" }}>
                                <TbCircleDotFilled  style={{ color: "green" }} />
                                <span>{category?.deviceId}</span>
                              </div>
                            ) : (
                              <>{category?.deviceId}</>
                            )}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>
                              {get(category, "deviceName", "N/A").split(" - ")[0]}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>
                              {" "}
                              <button
                                className="font-r1 signouttbn"
                                onClick={() => {
                                  setOpenModal(true);
                                  setUserId(get(category, "_id", ""));
                                }}
                              >
                                <IoIosLogOut style={{ color: "red" }} /> signout
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className={classes.tablePaginationStyle}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>

        {/* Modal for logout  */}
        <Modal
          maxWidth="lg"
          width="360px"
          RoundedCorners={true}
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          backgroundModal={false}
          backgroundModalContent={false}
          title={
            <div className="">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
              <TiInfoOutline style={{color:"red"}}/>
              <p className="ps-2" style={{color:"red"}}>Sign Out?</p>
                </div>
              <SlClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
              </div>
              <div className="logout-header">Are you sure want to Sign Out from this device ?</div>
            </div>
          }
          content={
            <>
              {/* <span className="are-you-sure">Are You Sure?</span> */}
              <div className="logout-button-group">
                <div className="logout-button" onClick={() => handleSignout()}>
                  Yes
                </div>
                <div
                  className="cancel-button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  No
                </div>
              </div>
            </>
          }
        />

      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageDevice));

import React, { useState, useEffect } from "react";
import { IoIosLogOut } from "react-icons/io";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
  Filter,
  DpBox,
  Fields,
  Resetbtn,
  Applybtn,
} from "./AccountManagementElements";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../components/Input";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaFilter, FaGlobe, FaPhone, FaUser, FaUserAlt } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { BsDot, BsEyeFill } from "react-icons/bs";
import { TiInfoOutline } from "react-icons/ti";
import { GrApple, GrAndroid } from "react-icons/gr";
import { SlClose } from "react-icons/sl";
import { Modal } from "../../components/Modal";
import SearchBar from "material-ui-search-bar";
import { bottom } from "@popperjs/core";
import {
  MdBlock,
  MdClose,
  MdEmail,
  MdLocalPhone,
  MdPhone,
} from "react-icons/md";
import {
  IoArchive,
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";
import {
  InputDivide,
  ProfileDetailForm,
} from "../Authentican/Profile/ProfileElements";
import PhoneInput from "react-phone-input-2";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#001050",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky:{
    position:"sticky",
    left:"0"
  }
}));

const AccountManagement = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const [tableData, SetTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");
const {state}=useLocation()
// For Pagination
const [page, setPage] =useState(state?.state?.page || 0);
console.log()
const [statePage,setStatePage]=useState(null)
console.log("one",statePage )
  // For Search
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(15);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(15);

console.log("state is ",state?.state?.page)
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(+event.target.value);
  window.scrollTo(0,0)
};
const handleChangePage = (event, newPage) => {
  setPage(newPage);
  window.scrollTo(0,0);
};
// Search functionalty
function myDeb(call, d = 1000) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      call(...args);
    }, d);
  };
}
useEffect(() => {
  // Check if `state?.state?.page` exists
  if (state?.state?.page != null) {
    setPage(state.state.page);
    setStatePage(null); // Reset the state page once it's used
  }
}, [state]);

useEffect(() => {
  getAccountManageData();
}, [page, searchTerm]);
  const getAccountManageData = async () => {
    setIsLoading(true);
   
    try {
      const { data } = await axios.get(
        `/private/accountMngt?search=${searchTerm}&page=${page +1}&limit=${limit}`
      );
      setPaginationData(data);
      SetTableData(data?.data);
      setShow(false);
      window.scrollTo(0,0)
    } catch (error) {
      if (error?.response.status === 401) {
        toast.success(`${error.response.data?.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        history.push("/");
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };



  



  const SearchAccountManagement = myDeb((search) => {
    setSearchTerm(search);
  });

  const cancelSearch = () => {
    setSearchTerm("");
  };

  const block = async (id) => {
    try {
      const response = await axios.put(`/private/changeStatus/${id}`);
      toast.success(response.data.message);
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const archive = async (id) => {
    try {
      let formData = {
        status: "3",
      };
      const response = await axios.put(`/changeAccountStatus/${id}`, formData);
      toast.success(response.data.message);
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const [show, setShow] = useState(false);

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <div
              style={{
                width: "95%",
                marginLeft: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DashboardHeading>
                <BsDot style={{ fontSize: "3rem", color: "#264503" }} />
                <DashHeading>Account Management</DashHeading>
              </DashboardHeading>
              <SearchBar
                style={{ minWidth: "18rem", borderRadius: "4px" }}
                className={"heightfix  "}
                onChange={(e) => {
                  SearchAccountManagement(e);
                }}
                onCancelSearch={cancelSearch}
                placeholder="Search"
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <Paper className={classes.paperTableHeight} onClick={()=>setShow(false)}>
              <>
                <TableContainer
                  className={classes.tableMainContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell className={classes.tablseHeadingCell}>
                          S.No
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Profile Name
                        </TableCell>
                        <TableCell className={[classes.tablseHeadingCell]} style={{position:"sticky",left:0,zIndex:50,background:"white"}}>
                          Email Id
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Country Code
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Phone Number
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Country
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          State
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          City
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          DOB
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Gender
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Device
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Referral Used
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Referral Code
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Referral Count
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Level
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Rank
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Total Distance
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Total Cycling
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((category, index) => (
                        <TableRow key={category._id}>
                          <TableCell className={classes.textMiddle}>
                          <div>{index + 1 + rowsPerPage*page}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "profileName", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle} style={{position:"sticky",left:0,zIndex:50,background:"white"}}>
                            <div>{get(category, "email", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div style={{whiteSpace:"nowrap"}}>
                              {get(category, "countryCode", "N/A")}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div style={{whiteSpace:"nowrap"}}>
                              {get(category, "phoneNumber", "N/A")}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div style={{whiteSpace:"nowrap"}}>{get(category, "country", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "state", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "city", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div  style={{whiteSpace:"nowrap"}}>{get(category, "dob", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "gender", "N/A") === "1" ? (
                              <span>Male</span>
                            ) : get(category, "gender", "N/A") === "2" ? (
                              <span>Female</span>   
                            ) : get(category, "gender", "N/A") === "3" ? (
                              <span>Other</span>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "deviceType", "N/A") === "1" ? (
                              <FaGlobe
                                style={{ fontSize: "20px", color: "#000000" }}
                              />
                            ) : get(category, "deviceType", "N/A") === "2" ? (
                              <GrAndroid
                                style={{ fontSize: "20px", color: "#79c257" }}
                              />
                            ) : get(category, "deviceType", "N/A") === "3" ? (
                              <GrApple
                                style={{ fontSize: "20px", color: "#424245" }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "referralCode", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "userReferralCode", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "referralCount", "0")} <br />{" "}
                            {
                              get(category, "referralCount", "0")!=0? <span
                              className="white-space-nowrap pointer underline"
                              onClick={() =>
                                history.push(
                                  `view-referral/${get(
                                    category,
                                    "userReferralCode"
                                  )}`,{state:{page:page}}
                                )
                              }
                            >
                              View Details
                            </span>:null
                            }
                           
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "CurrentLevel", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "CurrentRank", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "TotalWalk", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "TotalCycling", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div className="d-flex">
                              <div
                                className="font-g2 d-flex align-items-center me-4"
                                onClick={() =>
                                  history.push(
                                    `view-profile/${get(category, "_id")}`,{state:{page:page}}
                                  )
                                }
                              >
                                <BsEyeFill />{" "}
                                <span className="ps-2 tu">view</span>
                              </div>

                              {get(category, "accountStatus") != "3" ? (
                                <>
                                  <div
                                    className="font-r1 d-flex align-items-center me-4"
                                    onClick={() => block(get(category, "_id"))}
                                  >
                                    <MdBlock />
                                    <span className="ps-2 tu">
                                      {get(category, "status") == 1
                                        ? "unblock"
                                        : "block"}
                                    </span>
                                  </div>
                                  <div
                                    className="font-yellow d-flex align-items-center me-4"
                                    onClick={() =>
                                      archive(get(category, "_id"))
                                    }
                                  >
                                    <IoArchive />
                                    <span className="ps-2 tu">Archive</span>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className={classes.tablePaginationStyle}
                  rowsPerPageOptions={[15]}
                  component="div"
                  count={paginationData?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>

      {/* Modal for logout  */}
      <Modal
        maxWidth="lg"
        width="360px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <TiInfoOutline style={{ color: "red" }} />
                <p className="ps-2">Sign Out?</p>
              </div>
              <SlClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>
            <div className="logout-header">Hello</div>
          </div>
        }
        content={
          <>
            {/* <span className="are-you-sure">Are You Sure?</span> */}
            <div className="logout-button-group">
              <div
                className="logout-button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Yes
              </div>
              <div
                className="cancel-button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                No
              </div>
            </div>
          </>
        }
      />

      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountManagement));
